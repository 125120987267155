.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0rem 2rem;
    background-color: #000000;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
  }
  
  .navbar-logo img {
    height: 100px;
    width: 200px; 
    /* border-radius: 50%; */
  }
  
  .navbar-links {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;
  }
  
  .navbar-links li {
    margin: 0 2rem;
  }
  
  .navbar-links a {
    color: white;
    text-decoration: none;
    padding: 0.5rem 1.5rem;
  }
  
  .navbar-links a:hover {
    background-color: #575757;
    border-radius: 20px;
  }
  
/* Media Queries */
@media (max-width: 768px) {
  .navbar .nav-links {
    flex-direction: column;
    align-items: flex-start;
  }

  .navbar .nav-links li {
    margin-left: 0;
  }
}