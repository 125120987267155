.footer {
    background-color: #272727;
    color: white;
    padding: 20px 0;
    text-align: center;
  }
  
  .footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .footer-links {
    margin: 10px 0;
  }
  
  .footer-links a {
    color: white;
    margin: 0 10px;
    text-decoration: none;
  }
  
  .footer-links a:hover {
    text-decoration: underline;
  }
  
  .footer-social {
    margin: 10px 0;
  }
  
  .footer-social a {
    color: white;
    margin: 0 10px;
    font-size: 1.2em;
  }
  
  .footer-social a:hover {
    color: #ddd;
  }
  
/* Media Queries */
@media (max-width: 768px) {
  .footer {
    padding: 10px;
    font-size: 14px;
  }
}